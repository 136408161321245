@import 'bootstrap-variables';

/* ==============================================================
Bootstrap tweaks
===============================================================*/

body,
h1,
h2,
h3,
h4 {
  font-weight: 300;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
}

a {
  color: #533f03;
  font-weight: bold;
}

a:hover {
  color: #533f03;
  /* make sure browsers use the pointer cursor for anchors, even with no href */
  cursor: pointer;
}
.dropdown-item:hover,
.dropdown-item:focus {
  color: white;
  text-decoration: none;
  background-color: #373a3c;
}

/* ==========================================================================
Browser Upgrade Prompt
========================================================================== */
.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

/* ==========================================================================
Main page styles
========================================================================== */

.hipster.img-fluid {
  display: inline-block;
  width: 347px;
  height: 497px;
  background: url('../images/jhipster_family_member_2.svg') no-repeat center top;
  background-size: contain;
}

/* wait autoprefixer update to allow simple generation of high pixel density media query */
@media only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (min-device-pixel-ratio: 2),
  only screen and (min-resolution: 192dpi),
  only screen and (min-resolution: 2dppx) {
  .hipster {
    background: url('../images/jhipster_family_member_2.svg') no-repeat center top;
    background-size: contain;
  }
}

/* ==========================================================================
Generic styles
========================================================================== */

/* Error highlight on input fields */
.valid[required],
.valid.required {
  border-left: 5px solid green;
}

.invalid:not(form) {
  border-left: 5px solid red;
}

/* other generic styles */

.jh-card {
  padding: 1.5% !important;
  margin-top: 20px !important;
  border: none !important;
  background: inherit !important;
}

.error {
  color: white;
  background-color: red;
}

.pad {
  padding: 10px;
}

.w-40 {
  width: 40% !important;
}

.w-60 {
  width: 60% !important;
}

.break {
  white-space: normal;
  word-break: break-all;
}

.readonly {
  background-color: #eee;
  opacity: 1;
}

.footer {
  position: absolute;
  bottom: 60px;
  right: 20px;
  width: 50%;
  a {
    padding: 5px;
    position: absolute;
    right: 20px;
    background-color: rgba(255, 0, 0, 0.3);
    color: whitesmoke;
  }
}

.hand,
[jhisortby] {
  cursor: pointer;
}

/* ==========================================================================
Custom alerts for notification
========================================================================== */
.alerts {
  .alert {
    text-overflow: ellipsis;
    pre {
      background: none;
      border: none;
      font: inherit;
      color: inherit;
      padding: 0;
      margin: 0;
    }
    .popover pre {
      font-size: 10px;
    }
  }
  .toast {
    position: fixed;
    width: 100%;
    &.left {
      left: 5px;
    }
    &.right {
      right: 5px;
    }
    &.top {
      top: 55px;
    }
    &.bottom {
      bottom: 55px;
    }
  }
}

@media screen and (min-width: 480px) {
  .alerts .toast {
    width: 50%;
  }
}

/* ==========================================================================
entity tables helpers
========================================================================== */

/* Remove Bootstrap padding from the element
http://stackoverflow.com/questions/19562903/remove-padding-from-columns-in-bootstrap-3 */
@mixin no-padding($side) {
  @if $side == 'all' {
    .no-padding {
      padding: 0 !important;
    }
  } @else {
    .no-padding-#{$side} {
      padding-#{$side}: 0 !important;
    }
  }
}
@include no-padding('left');
@include no-padding('right');
@include no-padding('top');
@include no-padding('bottom');
@include no-padding('all');

/* bootstrap 3 input-group 100% width
http://stackoverflow.com/questions/23436430/bootstrap-3-input-group-100-width */
.width-min {
  width: 1% !important;
}

/* ==========================================================================
entity detail page css
========================================================================== */
.row.jh-entity-details {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 10px;
  line-height: 1.5;
}

@media screen and (min-width: 768px) {
  .row.jh-entity-details > {
    dt {
      float: left;
      overflow: hidden;
      clear: left;
      text-align: right;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding: 0.5em 0;
    }
    dd {
      border-bottom: 1px solid #eee;
      padding: 0.5em 0;
      margin-left: 0;
    }
  }
}

/* ==========================================================================
ui bootstrap tweaks
========================================================================== */
.nav,
.pagination,
.carousel,
.card-title a {
  cursor: pointer;
}

.datetime-picker-dropdown > li.date-picker-menu div > table .btn-secondary,
.uib-datepicker-popup > li > div.uib-datepicker > table .btn-secondary {
  border: 0;
}

.datetime-picker-dropdown > li.date-picker-menu div > table:focus,
.uib-datepicker-popup > li > div.uib-datepicker > table:focus {
  outline: none;
}

.thread-dump-modal-lock {
  max-width: 450px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* jhipster-needle-scss-add-main JHipster will add new css style */

.bg-navbar {
  background: rgba(0, 127, 128, 0.4);
}

.publication {
  background: rgba(255, 255, 255, 0.4);
  border: 2px solid rgb(33, 8, 0);
  border-radius: 10px;
  margin-bottom: 10px;
  h1 {
    background: rgba(33, 8, 0, 0.5);
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    color: whitesmoke;
  }
  div {
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
  }
}

/* sci fi elements*/

.navbar-light .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}

.public-stuff {
  .landing-link {
    margin: 20px;
  }
  .landing-links {
    margin: 10px;
    position: absolute;
    top: 50px;
    left: 50px;
  }
  .landing-actions {
    display: block;
    margin: 10px;
    position: absolute;
    top: 10px;
    right: 50px;
  }

  .inp {
    background: rgba(0, 127, 128, 0.68);
    -webkit-box-shadow: 0px 0px 12px rgba(0, 255, 255, 0.5);
    box-shadow: 0px 0px 12px rgba(0, 255, 255, 0.5);
    border: 1px solid rgba(128, 255, 255, 0.25);
    color: rgba(255, 255, 255, 0.75);
    text-shadow: 0 0 10px rgba(0, 255, 255, 0.95);
    font-size: 30px;
    padding: 10px 30px;
    text-shadow: 0 0 10px rgba(0, 255, 255, 0.5);
    outline: none;
  }
  .inp:hover {
    -webkit-box-shadow: 0px 0px 12px rgba(0, 255, 255, 0.75);
    box-shadow: 0px 0px 12px rgba(0, 255, 255, 0.75);
    border: 1px solid rgba(128, 255, 255, 0.75);
  }
  .inp:focus {
    -webkit-box-shadow: 0px 0px 12px rgba(0, 255, 255, 0.75);
    box-shadow: 0px 0px 12px rgba(0, 255, 255, 0.75);
    border: 1px solid rgba(128, 255, 255, 0.75);
  }
  .inp-secondary {
    background-color: transparent;
    color: rgba(0, 127, 128, 0.8);
    text-shadow: 0 0 12px rgba(0, 255, 255, 0.3);
    /* text-shadow: none; */
    border: 1px solid rgba(128, 255, 255, 0.5);
    -webkit-box-shadow: 0px 0px 12px rgba(0, 255, 255, 0.25);
    box-shadow: 0px 0px 12px rgba(0, 255, 255, 0.25);
  }
  .btn {
    background: rgba(0, 127, 128, 0.68);
    -webkit-box-shadow: 0px 0px 12px rgba(0, 255, 255, 0.5);
    box-shadow: 0px 0px 12px rgba(0, 255, 255, 0.5);
    border: 1px solid rgba(128, 255, 255, 0.25);
    color: rgba(255, 255, 255, 0.75);
    text-shadow: 0 0 10px rgba(0, 255, 255, 0.95);
    font-size: 30px;
    padding: 10px 30px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    outline: none;
  }
  .btn:hover {
    -webkit-box-shadow: 0px 0px 12px rgba(0, 255, 255, 0.75);
    box-shadow: 0px 0px 12px rgba(0, 255, 255, 0.75);
    border: 1px solid rgba(128, 255, 255, 0.75);
  }
  .btn-primary:active {
    background: rgba(0, 127, 128, 0.9);
  }
  .btn-secondary {
    background-color: transparent;
    color: rgba(0, 127, 128, 0.8);
    text-shadow: 0 0 12px rgba(0, 255, 255, 0.3);
    /* text-shadow: none; */
    border: 1px solid rgba(128, 255, 255, 0.5);
    -webkit-box-shadow: 0px 0px 12px rgba(0, 255, 255, 0.25);
    box-shadow: 0px 0px 12px rgba(0, 255, 255, 0.25);
  }
  .btn-secondary:active {
    -webkit-box-shadow: 0px 0px 12px rgba(0, 255, 255, 0.75);
    box-shadow: 0px 0px 12px rgba(0, 255, 255, 0.75);
  }

  .element {
    position: relative;
    width: 200px;
    height: 180px;
    color: #fff;
    text-align: center;
    background: rgba(0, 127, 128, 0.68);
    -webkit-box-shadow: 0px 0px 12px rgba(0, 255, 255, 0.5);
    box-shadow: 0px 0px 12px rgba(0, 255, 255, 0.5);
    border: 1px solid rgba(128, 255, 255, 0.25);
    cursor: default;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .element:hover {
    -webkit-box-shadow: 0px 0px 12px rgba(0, 255, 255, 0.75);
    box-shadow: 0px 0px 12px rgba(0, 255, 255, 0.75);
    border: 1px solid rgba(128, 255, 255, 0.75);
  }
  .element .number {
    font-size: 12px;
    color: rgba(128, 255, 255, 0.75);
    position: absolute;
    top: 20px;
    right: 20px;
  }
  .element .symbol {
    color: rgba(255, 255, 255, 0.75);
    text-shadow: 0 0 10px rgba(0, 255, 255, 0.95);
    position: absolute;
    font-size: 60px;
    font-weight: bold;
    top: 40px;
    left: 0px;
    right: 0px;
  }
  .element .details {
    font-size: 12px;
    color: rgba(128, 255, 255, 0.75);
    position: absolute;
    bottom: 15px;
    left: 0px;
    right: 0px;
  }

  .bg-text {
    margin: 10px;
    font-size: 12px;
    color: rgba(128, 255, 255, 0.75);
    bottom: 15px;
    left: 0px;
    right: 0px;
    width: 500px;
  }

  .bg-beats {
    float: left;
    margin: 10px;
    font-size: 12px;
    color: rgba(128, 255, 255, 0.75);
    bottom: 15px;
    width: 500px !important;
  }

  audio {
    filter: invert(80%) opacity(70%);
    height: 20px;
  }

  .bg-vids {
    float: left;
    margin: 10px;
    font-size: 12px;
    color: rgba(128, 255, 255, 0.75);
    bottom: 15px;
  }

  .yt-dialog {
    padding: 20px;
    background-color: rgba(127, 127, 0, 0.68);
    color: whitesmoke;
    border: 3px solid whitesmoke;
    font-size: larger;
  }
}
